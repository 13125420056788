import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CalAngularService } from "@cvx/cal-angular";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SessionStorageService } from "./session_storage";
import { RequestAccess } from "../interfaces/RequestAccess";
import { Attachment } from "../interfaces/Attachment";

@Injectable({
    providedIn: 'root'
  })
export class LFDAPIService{
    
    latApiBaseUri: string = environment.apiEndpoint
    authToken: any;
    constructor(private http: HttpClient, private authService: CalAngularService, private session:SessionStorageService) { 
      
    }
    getAuthorization(){
      return new HttpHeaders().set('Authorization','Bearer '+this.session.getItem('token'));
    }
    getAccessToken():  Observable<any> {
      return this.authService.getAADToken();  
    }

    sendEmail():Observable<any>{
        const data = {
            "email": [
                "ramkumarkommoju@chevron.com"
              ]
        }
       return this.http.post(this.latApiBaseUri+'email',data);
    }
    sendNotifyReviewerEmail(email: string, approval_team: string, approval_date: string): Observable<HttpResponse<any>>{
      const data = {
          "email": [
            email
            ]
      }
     return this.http.request('POST',this.latApiBaseUri+'email?app_team='+approval_team+'&app_date='+approval_date,{body: data ,observe:'response'});
    }

    createAttachment(file: any,proposalId:string,upstreamDissolutionChecklistUploaded:string,uploadedBy:string,uploadedByEmail:string,uploadedDate:string): Observable<HttpEvent<any> | HttpResponse<any>>{
      const formData = new FormData();
      formData.append('file',file);
      //formData.append('upstreamDissolutionChecklistUploaded',upstreamDissolutionChecklistUploaded);
      formData.append('uploadedBy',uploadedBy);
      formData.append('uploadedByEmail',uploadedByEmail)
      formData.append('uploadedDate',uploadedDate)
      return this.http.request('POST',this.latApiBaseUri+ '/AccessRequest/Attachments', {headers: new HttpHeaders({"Authorization":+ this.getAuthorization(), 'content-type': 'multipart/form-data'}),body: formData ,reportProgress:true,observe:'response'});
    }
 
    sendEmailNotification(emailData: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri+'/AccessRequest/SendNotification',{headers: this.getAuthorization(),body: emailData ,observe:'response'});
  }

    getApplications(appName : any): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.latApiBaseUri+ '/AccessRequest/GetBussinessApplicationDetails/'+ appName, {headers: this.getAuthorization(),observe:'response'});
    }

    getMyApprovals(data:any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri + '/AccessRequest/GetAccessRequestDetailsByApprover', {headers: this.getAuthorization(),body:data,observe:'response'});
    }

    getAppAccessRequestById(requestId : any): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.latApiBaseUri + '/AccessRequest/GetAccessRequest/'+ requestId, {headers: this.getAuthorization(),observe:'response'});
    }

    getAttachment(accessRequest : Attachment): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri + '/AccessRequest/GetAttachment', {headers: this.getAuthorization(),body:accessRequest,observe:'response',responseType: 'blob'});
    }

    getMyRequests(data:any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri + '/AccessRequest/GetAccessRequestDetailsByRequestor', {headers: this.getAuthorization(),body:data,observe:'response'});
    }

    saveAccessRequest(data : RequestAccess): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri + '/AccessRequest/saveRequestDetails', {headers: this.getAuthorization(),body:data,observe:'response',responseType: 'text'});
    }
    
    updateAppAccessRequestStatus(accessRequest : any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri + '/AccessRequest/UpdateApproverDetails', {headers: this.getAuthorization(),body:accessRequest,observe:'response',responseType: 'text'});
    }

    saveAttachment(fileData : FormData,attachmentId: number): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri + '/AccessRequest/SaveAttachment/' + attachmentId, {headers: this.getAuthorization(),body:fileData,observe:'response',responseType: 'text'});
    }

    saveAttachmentInDB(accessRequest : Attachment): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri + '/AccessRequest/SaveAttachmentInDB', {headers: this.getAuthorization(),body:accessRequest,observe:'response',responseType: 'text'});
    }
    
    deleteAttachment(accessRequest : Attachment): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.latApiBaseUri + '/AccessRequest/DeleteAttachment', {headers: this.getAuthorization(),body:accessRequest,observe:'response',responseType: 'text'});
    }
    
    

    triggerUsersAutoSyncjob(url:any): Observable<HttpResponse<any>>{
      return this.http.request('POST', url, {observe:'response'});
    }

    getAutoSyncjobUrl(): Observable<HttpResponse<any>>{
      return this.http.request('GET', this.latApiBaseUri + 'Admin/getAutoSyncjobUrl', { headers: this.getAuthorization() ,observe:'response'});
    }
    

}
