<main class="container">
    <section id="column1">
      <app-page-header></app-page-header>
      <div class="loader-container" *ngIf="isLoader">
        <nz-spin nzTip="Loading..." style="position: fixed;left: 45%;top:50%;z-index: 1000;" [nzSize]="'large'">
          <nz-alert
          nzType="info"
          nzMessage="Loading"
          nzDescription=" "
        ></nz-alert>
        </nz-spin>
      </div>
  <div class="class-content">  
    <div nz-row nzGutter="8">
     
      <div nz-col nzSpan="7">
        <form nz-form [formGroup]="searchForm" class="login-form" (ngSubmit)="submitForm()">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your Request No">
             <div class="sub-header">Search Filters</div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your Request No">
              <nz-input-group >
                <input type="text" nz-input formControlName="requestNo" placeholder="Request No" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your Requestor">
              <nz-input-group >
                <input type="text" nz-input formControlName="requestor" placeholder="Requestor" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your Requestor OnBehalf Of">
              <nz-input-group >
                <input type="text" nz-input formControlName="onbehalfOf" placeholder="Request On Behalf Of" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your Effective Date">
              <nz-input-group >
                <nz-date-picker formControlName="effectiveDate"  nzFormat="MM/dd/yyyy"  (ngModelChange)="onChangeEffectiveDate($event)" nzPlaceHolder="Effective Date"  style="width: 85%" nzspan="24">
                </nz-date-picker>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input Requested Date">
              <nz-input-group >
                <nz-date-picker formControlName="requestedDate"  nzFormat="MM/dd/yyyy"  (ngModelChange)="onChangeRequestedDate($event)" nzPlaceHolder="Requested Date"  style="width: 85%"  nzspan="24">
                </nz-date-picker>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your Status!">
              <nz-tree formControlName="status"
              [nzData]="nodes"
              nzCheckable
              nzMultiple
              [nzExpandedKeys]="defaultExpandedKeys"
              [nzCheckedKeys]="defaultCheckedKeys"
              (nzClick)="nzEvent($event)"
              (nzExpandChange)="nzEvent($event)"
              (nzCheckBoxChange)="nzEvent($event)"
            ></nz-tree>
          </nz-form-control>
          </nz-form-item>
          <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Search</button>
        </form>
      </div>
      <div nz-col nzSpan="16">
        <nz-table  *ngIf="accessRequests.length > 0" [nzFrontPagination]="false"  [nzData] = "accessRequests" nzTableLayout="fixed" nzSize="small" nzAlign="center">
          <thead>
              <tr>
                  <th nzWidth="15%">Req No</th>
                  <th>Requestor</th>
                  <th>Request On Behalf Of</th>
                  <th nzWidth="15%">Requested Date</th>
                  <th nzWidth="15%">Effective Date</th>
                  <th nzWidth="12%">Status</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let data of accessRequests">
                  <td ><a class="reqlink" (click)="ViewRequestDetails(data.request_Access_Order_Number)">{{data.request_Access_Order_Number}}</a></td>
                  <td nz-tooltip nzTooltipTitle={{data.requestor}}>{{data.requestor_DisplayName}}</td>
                  <td nz-tooltip nzTooltipTitle={{data.onBehalfOf}}>{{data.on_Behalf_Of_DisplayName}}</td>
                  <td nz-tooltip nzTooltipTitle={{data.requestedDate}}>{{data.createdDate | date: format}}</td>
                  <td nz-tooltip nzTooltipTitle={{data.effectiveDate}}>{{data.effectiveDate | date: format}}</td>
                  <td nz-tooltip nzTooltipTitle={{data.status}}>{{data.status}}</td>
              </tr>
          </tbody>
      </nz-table>
      <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="pageSize"  (nzPageIndexChange)="getPageIndexNumber($event)" (nzPageSizeChange)="getPageSizeNumber($event)" [nzTotal]="totalCount" [nzSize]="'small'" style="text-align: center;" ></nz-pagination>
      <nz-table *ngIf="accessRequests.length == 0">
        <thead>
          <tr>
            <th nzWidth="15%">Req No</th>
            <th>Requestor</th>
            <th>Request On Behalf Of</th>
            <th nzWidth="15%">Requested Date</th>
            <th nzWidth="15%">Effective Date</th>
            <th nzWidth="12%">Status</th>
          </tr>
      </thead>
      <tbody>
      </tbody>
      </nz-table>
      </div>
      <div nz-col nzSpan="1"></div>
    </div>

  </div>
    </section>
  </main>
  