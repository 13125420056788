import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { MsGraphService } from './core/services/ms-graph';

@Injectable({ providedIn: 'root' })
export class RoleGuardService  {
    
    private groups = environment.groupName;//['Law - Managed Services Team Members','Law - Function'];

    constructor(private authService: CalAngularService, private router: Router,private msgraph: MsGraphService) { }

    canActivate(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.msgraph.getGroupDetails(this.groups).subscribe(async (group: any) => {
                console.log('Group Details: ', group);
                if (group.value.length > 0) {
                    console.log('You are in one of the following groups: ' + this.groups + '.');
                    resolve(true);
                } else {
                    console.log('You are not in one of the following groups: ' + this.groups + '.');
                    this.router.navigateByUrl('/unauthorized');
                    resolve(false);
                }
            });
            console.log('Checking if you are in one of the following groups: ' + this.groups + '.');
        });
    }
}