import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { LFDAPIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { SessionStorageService } from 'src/app/core/services/session_storage';


@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.less']
})
export class PageHeaderComponent {
  @Input() displayStatus = false;
  userName: string = '';
  userPhoto: any;
  currentUserProfile!: ICvxClaimsPrincipal;
  currentStatus!: number;
  currentStep = 0;
  constructor(private session: SessionStorageService, private apiService: LFDAPIService, private msgraph: MsGraphService,
    private sanitizer: DomSanitizer, private authService: CalAngularService, private dataservice: DataService){} 

  ngOnInit(){
    if(!this.dataservice.currentUser){
      this.authService.getUserPropertiesFromMsGraph(["givenName","cai", "jobTitle","photo","surname"]).subscribe(graphProp =>{
        this.userName = graphProp.givenName;
        this.dataservice.currentUser = this.userName;
//        this.getAndSetPhoto();

        this.authService.getAADToken().subscribe(result => {
          this.session.setItem('photoToken', JSON.parse(JSON.stringify(result)))
          this.getAndSetPhoto();
        });
      });
    }else{
      this.userName = this.dataservice.currentUser;
      this.userPhoto = this.dataservice.currentUserPhoto;
    }
    // this.getStatuses();
  }
  // getStatuses(){
  //   this.apiService.getStatuses().subscribe({
  //     next: (out) =>{
  //       this.statuses = [...out.body];
  //       this.dataservice.statuses = this.statuses;
  //     },
  //     error: (err) =>{
  //       console.log("Unable to retrieve statuses",err);
  //     },
  //     complete: () =>{
  
  //     }
  //   });
  // }
  getAndSetPhoto() {       
    this.msgraph.getUserPhoto().subscribe(data => {
      let jsonKey: string = "";
      new Uint8Array(data).forEach(function(byte:number){jsonKey+=String.fromCharCode(byte)})
      let base64String = btoa(jsonKey);
      localStorage.setItem('photo', base64String);
      this.userPhoto = this.sanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64, ' + base64String
      );
      this.dataservice.currentUserPhoto = this.userPhoto;
      
    });
  }
  

}
