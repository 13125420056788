import { Component, ViewChild, EventEmitter, Input, Optional, Output} from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent, NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { CalAngularService } from '@cvx/cal-angular';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { RequestAccess, applicationAccessRequests } from 'src/app/core/interfaces/RequestAccess';
import { EmailNotification } from 'src/app/core/interfaces/EmailNotification';
import { formatDate } from '@angular/common';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ServiceNowURL } from 'src/app/constants/constants';
import { saveAs } from "file-saver";
import { EmailService } from 'src/app/core/services/email_service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-requestapproval',
  templateUrl: './requestapproval.component.html',
  styleUrls: ['./requestapproval.component.less'],
  animations: [formControlElement, formLabelElement]
})
export class RequestapprovalComponent {
  isLoader:boolean = false;
  isSaveLoader:boolean = false;
  accessRequests: RequestAccess[] = [];
  applicationData: any[] = [];
  attachment: Attachment = {
    Id: 0,
    ApplicationAccessRequestId: 0,
    fileURL: "",
    fileName: "",
    CreatedBy: "",
    LastModifiedBy:""
  }
  attachments: Attachment[] = [];
  application: applicationAccessRequests = {
    id: 0,
    appID: "",
    appName: "",
    sysId: "",
    appDescription: "",
    shortDescription: true,
    appBusinessOwner: "",
    mimicAzureObjectId: "",
    requestor_Comments: ""
  };
  requestaccessId: string = "";
  requestStatus: string = "";
  isAttachmentsVisible: boolean = false;
  isShowApplication: boolean = false;
  checkAllApps: boolean = false;
  checkAttachment: boolean = false;
  isapprovalDisable: boolean = true;
  isRejectCommentsEntered: boolean = false;
  showAttachmentDelete: boolean = false;
  calledFrom: string = "";
  isShowUpload: boolean = false;
  IsReadOnly: boolean = false;
  emailNotification: EmailNotification = {
    requestNo: "",
    requestor: "",
    requestorName: "",
    approver: "",
    approverName: "",
    status: "",
    event: "",
    subject: "",
    effectiveDate: "",
    requestedDate: "",
    onBehalfOf: "",
    onBehalfOfName: ""
  }

  constructor(private fb: UntypedFormBuilder,private route: ActivatedRoute, private emailService: EmailService, private apiService: LFDAPIService, private modal: NzModalService, private msgraph: MsGraphService , private authService: CalAngularService,private router: Router, private session: SessionStorageService, private dataservice: DataService) {
    
  if(this.session.getItem('requestAccessId') != null && this.session.getItem('requestAccessId') != "") {
    this.requestaccessId = this.session.getItem('requestAccessId');
  }
  if(this.session.getItem('calledFrom') != null && this.session.getItem('calledFrom') != "") {
    this.calledFrom = this.session.getItem('calledFrom');
  }
  }

   
  async ngOnInit() {
    this.isLoader = true;
    let result = await this.dataservice.getAuthorizationToken();
    if(result){
      await this.getRequestIdFromUrlPath();
      this.getRequestDetails();
    }
 }

 getRequestDetails(flag?: string) {
  this.isapprovalDisable = true;
  this.apiService.getAppAccessRequestById(this.requestaccessId).subscribe({
    next: (data) => {
    this.accessRequests = [];
    this.applicationData = [];
    this.requestStatus= data.body.status;
    this.accessRequests.push({
      id: data.body.id,
      request_Access_Order_Number: data.body.request_Access_Order_Number,
      requestor_DisplayName: data.body.requestor_DisplayName,
      requestor_Email: data.body.requestor_Email,
      requestor_AzureObjectID: data.body.requestor_AzureObjectID,
      requestor_Title: data.body.requestor_Title,
      on_Behalf_Of_DisplayName: data.body.on_Behalf_Of_DisplayName,
      on_Behalf_Of_AzureObjectID: data.body.on_Behalf_Of_AzureObjectID,
      on_Behalf_Of_Email: data.body.on_Behalf_Of_Email,
      location: data.body.location,
      sysid: data.body.sysid,
        onBehalfOf: true,
        approver_DisplayName: data.body.approver_DisplayName,
        approver_AzureObjectID: data.body.approver_AzureObjectID,
        approver_Email: data.body.approver_Email,
        environment: data.body.environment,
        status: data.body.status,
        effectiveDate: formatDate(data.body.effectiveDate.toLocaleString("en-US"), 'MM-dd-yyyy', 'en-US'),
        applicationAccessRequests: data.body.applicationAccessRequests,
        createdBy: data.body.createdBy,
        lastModifiedBy: data.body.lastModifiedBy,
        lastModifiedDate: formatDate(data.body.lastModifiedDate.toLocaleString("en-US"), 'MM-dd-yyyy', 'en-US'),
        createdDate: formatDate(data.body.createdDate.toLocaleString("en-US"), 'MM-dd-yyyy', 'en-US')
      });
      data.body.applicationAccessRequests.forEach((element: any) => {
        this.applicationData.push({
          id: element.id,
          appID: element.appID,
          appName: element.appName,
          sysId: element.sysId,
          appDescription: element.appDescription,
          shortDescription: element.shortDescription,
          appBusinessOwner: element.appBusinessOwner,
          appProductLine: element.appProductLine,
          appMimic_DisplayName: element.appMimic_DisplayName,
          appMimic_AzureObjectID: element.appMimic_AzureObjectID,
          requestor_Comments: element.requestor_Comments,
          supervisor_Comments: element.supervisor_Comments,
          appChecked: false,
          appRejected: false,
          status: element.status,
          attachments: element.attachments,
          serviceTaskId: element.serviceTaskId,
          serviceNowRequestStatus: element.serviceNowRequestStatus,
          serviceNowIDLink: ServiceNowURL + element.accessRequest
        });
      });
      if(this.accessRequests[0].status == 'Review Complete' && flag == 'Save' && environment.sendEmail) {
        //this.sendEmailNotification();
      }
      this.authService.getUserPropertiesFromMsGraph(["id,displayName","cai", "jobTitle","photo","surname","givenName","officeLocation"]).subscribe(graphProp =>{
        if(graphProp.id == this.accessRequests[0].requestor_AzureObjectID) {
          //if(this.accessRequests[0].requestor_AzureObjectID == "e4be06d4-19e8-4c05-949e-d97888c10496") {
          this.IsReadOnly = true;
          console.log('graphProp.id',graphProp.id);
          console.log('this.accessRequests[0].requestor_AzureObjectID',this.accessRequests[0].requestor_AzureObjectID);
          console.log(' this.IsReadOnly', this.IsReadOnly);
        }
      });
      
      
      this.isLoader = false;
  },
  error: (err) => {
    this.isLoader = false;
  },
  complete: () => {
    this.isLoader = false;
  }
});
 }

 showUpload(event: boolean) {
  console.log('event', event);
  this.checkAttachment = event;
  if(event && this.showAttachmentDelete == true) {
    this.isShowUpload = true;
  }
  else {
    this.isShowUpload = false;
  }
 }  

cancelAddAttachmentPopup() {
  this.isAttachmentsVisible = false;
  this.getRequestDetails();
 }

  selectAllApps(event: boolean) {
    console.log('event', event);
    this.checkAllApps = event;
    if(event) {
      this.isapprovalDisable = false;
    } else {
      this.isapprovalDisable = true;
    }
    this.applicationData.forEach(val => {
      val.appChecked = event;
    });
  }

  selectApp(event: boolean,data:any) {
    console.log('event', event);
    console.log('data', data);
    data.appChecked = event;
    data.appRejected = false;
    let checkedApps = this.applicationData.filter((x: any) => x.appChecked === true);
    if(checkedApps.length > 0) {
      this.isapprovalDisable = false;
    } else {
      this.isapprovalDisable = true;
    }
  }

  showAddAttachmentPopup(data: any) {
    this.checkAttachment = false;
    this.isShowUpload = false;
    this.showAttachmentDelete = false;
    this.application.id = data.id;
    this.isAttachmentsVisible = true;
    this.getAttachmentList();
  }

  cancelShowApplication() {
    this.isShowApplication = false;
    
  }

  showApplication(data: any)
  {
    this.application.appID = data.appID;
    this.application.appName = data.appName;
    this.application.appDescription = data.appDescription;
    this.application.appProductLine = data.appProductLine; 
    this.isShowApplication = true;
  }

  downloadFile(byteArray: Uint8Array, fileName: string, mimeType: string): void {
    const blob = new Blob([byteArray], { type: mimeType });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;//"data:application/octet-stream;base64," + byteArray;
    link.setAttribute('download', fileName);
    link.setAttribute('target', "_blank");

    //document.body.appendChild(link);
    link.click();
    // document.body.removeChild(link);
    // window.URL.revokeObjectURL(url);
  }

  getAttachment(attachment: any) {
    this.attachment.fileName = attachment.fileName;
    this.attachment.ApplicationAccessRequestId = this.application.id;
    this.attachment.Id = attachment.id
    this.attachment.fileName = attachment.fileName;
    this.apiService.getAttachment(this.attachment)
    .subscribe({
      next: (data) => {
       console.log('File downloaded successfully');
        saveAs(data.body, attachment.fileName);
      },
     
    } );
  }

  deleteAttachment(attachment: any) {
    this.isLoader = true;
        this.attachment.fileName = attachment.fileName;
        this.attachment.ApplicationAccessRequestId = this.application.id;
        this.attachment.Id = attachment.id
        this.attachment.fileName = attachment.fileName;
        this.apiService.deleteAttachment(this.attachment).subscribe({
          next: (data) => {
            console.log('File deleted successfully', data);
          },
          error: (err) => {
            console.log(err);
            this.isLoader = false;
          },
          complete: () => {
            console.log('Completed');
            this.getAttachmentList();
            this.isLoader = false;
          }
        });
      
  }

  nzAction = (item: any) => {
    this.isLoader = true;
    const formData = new FormData();
    formData.append(item.name, item.file as any);
    return this.apiService.saveAttachment(formData,this.application.id).subscribe(
    {
      next: (fileUrl) => {
        this.attachment.Id = this.application.id;
        this.attachment.fileURL = fileUrl.body;
        this.attachment.fileName = item.file.name;
        this.attachment.CreatedBy = this.authService.cvxClaimsPrincipal.name;
        this.attachment.LastModifiedBy = this.authService.cvxClaimsPrincipal.name;
        this.apiService.saveAttachmentInDB(this.attachment).subscribe({
          next: (data: any) => {
            console.log('File uploaded successfully', fileUrl);
          },
          error: (err: any) => {
            console.log(err);
            this.isLoader = false;
          },
          complete: () => {
            console.log('Completed');
            this.getAttachmentList();
            this.isLoader = false;
          }
        });
      }
    });
  }

  onBack() {
    if(this.calledFrom !="" && this.calledFrom == 'my_approvals') {
      this.session.setItem("calledFrom","");
      this.router.navigate(['/my_dashboard/my_approvals']);
    }
    else {
      this.session.setItem("calledFrom","");
      this.router.navigate(['/my_dashboard/my_requests']);
    }
    
  }

  getAttachmentList() {
    this.apiService.getAppAccessRequestById(this.requestaccessId).subscribe({
      next: (data) => {
        let currentAttachmentAccessRecord = data.body.applicationAccessRequests.filter((x: any) => x.id === this.application.id);
        if(currentAttachmentAccessRecord.length > 0 && currentAttachmentAccessRecord[0].attachments.length > 0) {
          this.attachments = currentAttachmentAccessRecord[0].attachments;
        }
        else
        {
          this.attachments = [];
        }
        if (currentAttachmentAccessRecord[0].status == 'Pending Review') {
          if(this.IsReadOnly) {
            this.showAttachmentDelete = false;
          }
          else {
          this.showAttachmentDelete = true;
          }
        }
        else {
          this.showAttachmentDelete = false;
          }
        console.log(this.applicationData);
        this.isLoader = false;
    },
    error: (err) => {
      this.isLoader = false;
    },
    complete: () => {
      this.isLoader = false;
    }
  });
  }

  UpdateStatus(status: string) {
    this.isSaveLoader = true;
    this.isapprovalDisable = true;
    window.scrollTo(0, 0);
    let updateRequest: any[] = [];
    this.applicationData.forEach((appRecord: any) => {
      if(appRecord.appChecked) {
        updateRequest.push({
          Id: appRecord.id,
          status: status,
          supervisor_Comments: appRecord.supervisor_Comments,
       
        });
        if(status == 'Reject' && (appRecord.supervisor_Comments == '' || appRecord.supervisor_Comments == null)) {
          appRecord.appRejected = true;
          this.isRejectCommentsEntered = true;
          console.log('Please enter comments',this.isRejectCommentsEntered);
        }
        else if(status == 'Reject' && (appRecord.supervisor_Comments != '' || appRecord.supervisor_Comments != null)) {
          appRecord.appRejected = false;
          this.isRejectCommentsEntered = false;
        }
        else if(status == 'Approve') { 
          appRecord.appRejected = false;
          this.isRejectCommentsEntered = false;
          
        }
      }
  
  });
  if (this.isRejectCommentsEntered == false) {
  let requestAccessUpdate = {
    Request_Access_Order_Number: this.requestaccessId,
    sysId: '',
    ServiceNowID: '',
    ServiceNowRequestStatus: '',
    AppMimic_AzureObjectID: '',
    AppMimic_DisplayName:'',
    LastModifiedBy:'',
    CreatedBy:'',
    status: status,
    updateApplicationStatus: updateRequest
  }
console.log('updateRequest', requestAccessUpdate);
  this.apiService.updateAppAccessRequestStatus(requestAccessUpdate).subscribe({
    next: (data) => {
      
      this.isSaveLoader = false;
      this.modal.success({
        nzTitle: 'success message',
        nzContent: 'Request updated successfully',
        nzOnOk: () => this.getRequestDetails('Save')
      });
  },
  error: (err) => {
    this.modal.error({
      nzTitle: 'error message',
      nzContent: 'Unable to update request',
      nzOnOk: () => this.navigateToPageComponent('/home')
    });
    this.isSaveLoader = false;
  },
  complete: () => {
    this.isSaveLoader = false;
}

});
  }
  else
  {
    this.isLoader = false;
  }
}

async sendEmailNotification() {
  this.emailNotification.requestNo=this.requestaccessId;
  this.emailNotification.requestor= this.accessRequests[0].requestor_Email;
  this.emailNotification.requestorName= this.accessRequests[0].requestor_DisplayName;
  this.emailNotification.approver = this.accessRequests[0].approver_Email;
  this.emailNotification.approverName = this.accessRequests[0].approver_DisplayName;
  this.emailNotification.status = this.accessRequests[0].status;
  this.emailNotification.event=  "Review Complete";
  this.emailNotification.subject = "[Review Required] Law Access request RAON000" + this.requestaccessId + " for " + this.accessRequests[0].requestor_DisplayName;
  this.emailNotification.effectiveDate = this.accessRequests[0].effectiveDate;
  this.emailNotification.requestedDate = this.accessRequests[0].createdDate;
  this.emailNotification.onBehalfOf=  this.accessRequests[0].on_Behalf_Of_Email;
  this.emailNotification.onBehalfOfName=  this.accessRequests[0].on_Behalf_Of_DisplayName;

 (await this.emailService.sent_email_notification(this.emailNotification)).subscribe({
    next: (emailResponse: any) => {
      console.log('emailResponse', emailResponse);
    },
    error: (err: any) => {
      console.log('Error sending email', err);
    }
  });
}

async getRequestIdFromUrlPath(){
  let arrVal:string[]=this.router.url.split('/');
  this.isLoader = true;
  return await new Promise<boolean>((resolve) =>{
    if(arrVal[arrVal.length-1].includes('RAON000')){
      this.requestaccessId = arrVal[arrVal.length-1];
      this.session.setItem('requestAccessId',this.requestaccessId);
      this.isLoader = false;
      resolve(true);
    }else{
      this.isLoader = false;
      resolve(true);
    }
  })
}

navigateToPageComponent(data: string){
  this.router.navigate([data]);
}
}

