import { Injectable } from "@angular/core"
import { LFDAPIService } from './api';
import { environment } from "src/environments/environment";
import { EmailNotification } from "../interfaces/EmailNotification";
import { Observable, map } from "rxjs";
import { HttpResponse } from "@angular/common/http"

@Injectable({
    providedIn: 'root'
  })

export class EmailService {
    
    latApiBaseUri: string = environment.apiEndpoint
    authToken: any;
    constructor(private apiService: LFDAPIService) { 
      
    }

    async sent_email_notification(requestNotification: EmailNotification): Promise<Observable<HttpResponse<any>>> {
        let email_recipient = requestNotification.requestor;
        let approver = requestNotification.approver;
        let email_subject = requestNotification.subject;
        let email_body = this.get_email_body(requestNotification);
        const data = {
            mailFrom:"lawaccesstool_noreply@chevron.com",
            mailTo: approver,
            mailCC: email_recipient,
            mailSubject: email_subject,
            mailContent: email_body
          }

          return this.apiService.sendEmailNotification(data).pipe(
            map(emailResponse => {
              return emailResponse;
            })
          );
    }

    get_email_body(requestNotification: EmailNotification): string {
        let html_tb_body = `<tr>
        <td style="width:30%"><strong>Request Id:</strong></td>
        <td>${requestNotification.requestNo}</tdh>
        </tr>
        <tr>
            <td><strong>Status:</strong></td>
            <td>${requestNotification.status}</td>
        </tr>
       `
if (requestNotification.event == "Pending Review")
html_tb_body = `${html_tb_body}
          `
let html_body = '';

html_body = `
<p class="MsoNormal">
    Hello ${requestNotification.approverName},
    <br>
    <br>
    Law application access request is raised for ${requestNotification.requestorName}.
    <br>
    <br>
    Please click on <a href=${environment.webUrl}/requestapproval/${requestNotification.requestNo}>${requestNotification.requestNo}</a> to review the request.
</p>
<p class="MsoNormal"> <span style="text-align: center;">
</span></p>
<br>
<br>
Regards,
<br>
Law Access Tool
<br>
<br>
This is an auto generated message. Please do not reply to this email. For help, submit a request <a href="http://go.chevron.com/LawApplicationSupport">here</a>.
`


return html_body
    }
}
