import { Component, ViewChild, EventEmitter, Input, Optional, Output} from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { Router } from '@angular/router';
import { AlertComponent, NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { CalAngularService } from '@cvx/cal-angular';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { differenceInCalendarDays } from 'date-fns';
import { RequestAccess } from 'src/app/core/interfaces/RequestAccess';
import { formatDate } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ServiceNowURL } from 'src/app/constants/constants';
import { EmailNotification } from 'src/app/core/interfaces/EmailNotification';
import { EmailService } from 'src/app/core/services/email_service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  animations: [formControlElement, formLabelElement], 
})

export class HomeComponent {

  isLoader:boolean = false; 
  isSaveLoader:boolean = false;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  applications:any= [];
  isAddApplicationVisible = false;
  azureObjectId: string = '';
  userName: string = '';
  approverEmail: string = '';
  approverUserName: string = '';
  userRole: string = '';
  onBehalfOfUser: string = '';
  environment = "Production";
  applicationData: any[] = [];
  getApplicationData: any[] = [];
  accessRequests: RequestAccess[] = [];
  searchUsers: any[] = [];
  searchApplications: any[] = [];
  isonBehalfOfdisable: boolean = true;
  isCheckedBehalfOf: boolean = false;
  on_Behalf_Of_DisplayName: string = '';
  application: any[] = [];
  searchappName: any;
  date = new Date();
  effectiveDate = this.date.setDate(new Date().getDate() + 7);
  isAppSelected: boolean = false;
  requestaccessId: string = "";

  navigateURL: string = "";
  selectedApp: any;
  checkAllApps: boolean = false;
  emailNotification: EmailNotification = {
    requestNo: "",
    requestor: "",
    requestorName: "",
    approver: "",
    approverName: "",
    status: "",
    event: "",
    subject: "",
    effectiveDate: "",
    requestedDate: "",
    onBehalfOf: "",
    onBehalfOfName: ""
  }

  reqAcessForm = this.fb.group({
    requestor: [null],
    onBehalfOfUser: [null],
    onBehalfOf: [false],
    title: [null],
    approver: [null],
    environment: [null],
    effectiveDate: [null],
  });

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.effectiveDate) < 0;

  requestAccessFormModel: RequestAccess = {
    id: 0,
    request_Access_Order_Number: '',
    requestor_AzureObjectID: '',
    requestor_DisplayName: '',
    requestor_Email: '',
    on_Behalf_Of_AzureObjectID: '',
    on_Behalf_Of_DisplayName: '',
    on_Behalf_Of_Email: '',
    onBehalfOf: false,
    requestor_Title: '',
    location: '',
    approver_DisplayName: '',
    approver_AzureObjectID: '',
    approver_Email: '',
    sysid: '',
    environment: 'Production',
    status: 'New',
    effectiveDate: new Date().getDate().toLocaleString("en-US"),
    applicationAccessRequests: [],
    createdBy: '',
    createdDate: new Date().getDate().toLocaleString("en-US"),
    lastModifiedBy: '',
    lastModifiedDate: new Date().getDate().toLocaleString("en-US"),
  };

  
  constructor(private fb: UntypedFormBuilder,private apiService: LFDAPIService, private emailService: EmailService, private msgraph: MsGraphService , private authService: CalAngularService,private router: Router, private modal: NzModalService, private session: SessionStorageService, private dataservice: DataService) {
  }

 async ngOnInit() {
    this.isLoader =true;
    this.isAppSelected = false
    let result = await this.dataservice.getAuthorizationToken();
    if(result){
        this.reqAcessForm.controls["requestor"].disable();
        this.reqAcessForm.controls["title"].disable();
        this.reqAcessForm.controls["approver"].disable();
        this.reqAcessForm.controls["onBehalfOfUser"].disable();
        this.reqAcessForm.controls["environment"].disable();
        this.addDays(new Date());
        this.requestAccessFormModel.createdDate = formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US');
        this.requestAccessFormModel.lastModifiedDate = formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US');
        this.getUserDetails();
      }
      this.isLoader = false;
  }

  async getUserDetails(){
    this.authService.getUserPropertiesFromMsGraph(["id,displayName", "mail", "jobTitle","photo","surname","givenName","officeLocation"]).subscribe(graphProp =>{
      this.requestAccessFormModel.requestor_AzureObjectID = graphProp.id;
      this.requestAccessFormModel.requestor_DisplayName = graphProp.surname + ", " + graphProp.givenName;
      this.requestAccessFormModel.requestor_Email = graphProp.mail;
      this.requestAccessFormModel.location = graphProp.officeLocation;
      this.reqAcessForm.controls["requestor"].setValue(this.requestAccessFormModel.requestor_DisplayName);
      this.requestAccessFormModel.requestor_Title = graphProp.jobTitle;
      this.reqAcessForm.controls["title"].setValue(this.requestAccessFormModel.requestor_Title);
      console.log('this.azureObjectId', this.azureObjectId);
      this.msgraph.getRequestorSupervisor(this.requestAccessFormModel.requestor_AzureObjectID).subscribe(data => {
        this.requestAccessFormModel.approver_DisplayName = data.surname + ", " + data.givenName;
        this.requestAccessFormModel.approver_AzureObjectID = data.id;
        this.requestAccessFormModel.approver_Email = data.mail;
        this.reqAcessForm.controls["approver"].setValue(this.requestAccessFormModel.approver_DisplayName);
      });
    });
  }

  addApplication() {
    this.isAddApplicationVisible = true;
    this.application = [];
    this.searchApplications = [];
    this.searchappName = '';
    this.isAppSelected = false
  }

  cancelAddApplicationPopup() {
    this.isAddApplicationVisible = false;
  }

  addDays(date: Date): void {
    date.setDate(new Date().getDate() + 7);
    this.reqAcessForm.controls["effectiveDate"].setValue(date);
    
  }

  sendemail(): void {
    this.apiService.sendEmail().subscribe(value => {
      console.log(value);
    });
  }

  submitAccessRequest() {
    this.isSaveLoader = true;
    window.scrollTo(0, 0);
    this.requestAccessFormModel.requestor_DisplayName = this.reqAcessForm.controls["requestor"].value;
    this.requestAccessFormModel.onBehalfOf = this.reqAcessForm.controls["onBehalfOf"].value;
    this.requestAccessFormModel.on_Behalf_Of_DisplayName =this.on_Behalf_Of_DisplayName;
    this.requestAccessFormModel.environment = this.reqAcessForm.controls["environment"].value;
    this.requestAccessFormModel.effectiveDate = this.reqAcessForm.controls["effectiveDate"].value;
    this.requestAccessFormModel.applicationAccessRequests = this.applicationData;
    this.requestAccessFormModel.createdBy = this.requestAccessFormModel.requestor_DisplayName;
    this.requestAccessFormModel.lastModifiedBy = this.requestAccessFormModel.requestor_DisplayName;
    this.requestAccessFormModel.createdDate = formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US');
    this.requestAccessFormModel.lastModifiedDate = formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US');
    console.log('this.requestAccessFormModel', this.requestAccessFormModel);
    this.apiService.saveAccessRequest(this.requestAccessFormModel).subscribe({
      next: async (out) => {
        this.requestaccessId = "RAON000" + out.body;
        this.sendEmailNotification();
       console.log('out', out);
      },
      error: (err) => {
        console.log("Unable to submit request", err);
        this.modal.error({
          nzTitle: 'error message',
          nzContent: 'Unable to submit request',
          nzOnOk: () => this.navigateToPageComponent('/home')
        });
        this.isSaveLoader = false;
      },
      complete: () => {
        console.log("Request submitted successfully");
        
        this.modal.success({
          nzTitle: 'success message',
          nzContent: 'Request submitted successfully',
          nzOnOk: () => this.navigateToPageComponent('/home')
        });
        this.isSaveLoader = false;
    }
  });
}

async getRequestDetails() {
  this.apiService.getAppAccessRequestById(this.requestaccessId).subscribe({
    next: (data) => {
    this.accessRequests = [];
    this.applicationData = [];
    this.accessRequests.push({
      id: data.body.id,
      request_Access_Order_Number: data.body.request_Access_Order_Number,
      requestor_DisplayName: data.body.requestor_DisplayName,
      requestor_Email: data.body.requestor_Email,
      requestor_AzureObjectID: data.body.requestor_AzureObjectID,
      requestor_Title: data.body.requestor_Title,
      on_Behalf_Of_DisplayName: data.body.on_Behalf_Of_DisplayName,
      on_Behalf_Of_AzureObjectID: data.body.on_Behalf_Of_AzureObjectID,
      on_Behalf_Of_Email: data.body.on_Behalf_Of_Email,
      location: data.body.location,
      sysid: data.body.sysid,
        onBehalfOf: true,
        approver_DisplayName: data.body.approver_DisplayName,
        approver_AzureObjectID: data.body.approver_AzureObjectID,
        approver_Email: data.body.approver_Email,
        environment: data.body.environment,
        status: data.body.status,
        effectiveDate: formatDate(data.body.effectiveDate.toLocaleString("en-US"), 'MM-dd-yyyy', 'en-US'),
        applicationAccessRequests: data.body.applicationAccessRequests,
        createdBy: data.body.createdBy,
        lastModifiedBy: data.body.lastModifiedBy,
        lastModifiedDate: formatDate(data.body.lastModifiedDate.toLocaleString("en-US"), 'MM-dd-yyyy', 'en-US'),
        createdDate: formatDate(data.body.createdDate.toLocaleString("en-US"), 'MM-dd-yyyy', 'en-US')
      });
      data.body.applicationAccessRequests.forEach((element: any) => {
        this.applicationData.push({
          id: element.id,
          appID: element.appID,
          appName: element.appName,
          sysId: element.sysId,
          appDescription: element.appDescription,
          shortDescription: element.shortDescription,
          appBusinessOwner: element.appBusinessOwner,
          appProductLine: element.appProductLine,
          appMimic_DisplayName: element.appMimic_DisplayName,
          appMimic_AzureObjectID: element.appMimic_AzureObjectID,
          requestor_Comments: element.requestor_Comments,
          supervisor_Comments: element.supervisor_Comments,
          appChecked: false,
          appRejected: false,
          status: element.status,
          attachments: element.attachments,
          serviceTaskId: element.serviceTaskId,
          serviceNowRequestStatus: element.serviceNowRequestStatus,
          serviceNowIDLink: ServiceNowURL + element.accessRequest
        });
      });
      this.isLoader = false;
  },
  error: (err) => {
    this.isLoader = false;
  },
  complete: () => {
    this.isLoader = false;
  }
});
 }

async sendEmailNotification() {
  if (environment.sendEmail) {
  //await this.getRequestDetails()
  this.emailNotification.requestNo=this.requestaccessId;
  this.emailNotification.requestor= this.requestAccessFormModel.requestor_Email;
  this.emailNotification.requestorName= this.requestAccessFormModel.requestor_DisplayName;
  this.emailNotification.approver = this.requestAccessFormModel.approver_Email;
  this.emailNotification.approverName = this.requestAccessFormModel.approver_DisplayName;
  this.emailNotification.status = "Pending Review";
  this.emailNotification.event=  "Pending Review";
  this.emailNotification.subject = "[Review Required] Law Access request " + this.requestaccessId + " for " + this.requestAccessFormModel.requestor_DisplayName;
  this.emailNotification.effectiveDate = this.requestAccessFormModel.effectiveDate;
  this.emailNotification.requestedDate = this.requestAccessFormModel.createdDate;
  this.emailNotification.onBehalfOf=  this.requestAccessFormModel.on_Behalf_Of_Email;
  this.emailNotification.onBehalfOfName=  this.requestAccessFormModel.on_Behalf_Of_DisplayName;

 (await this.emailService.sent_email_notification(this.emailNotification)).subscribe({
    next: (emailResponse: any) => {
      console.log('emailResponse', emailResponse);
    },
    error: (err: any) => {
      console.log('Error sending email', err);
    }
  });
}
}

  getApplication() {
    this.isLoader = true;
    this.searchApplications.forEach(val => {
      if(val.appChecked){
        this.applicationData.push({
          appId: val.appId,
          appName: val.appName,
          shortDescription: val.appShortDesc,
          appDescription: val.appDesc,
          appProductLine: val.appProductLine,
          appMimic_DisplayName: "",
          requestor_Comments: "",    
          sysId: val.sysId,
          createdDate: formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US'),
          createdBy: this.requestAccessFormModel.requestor_DisplayName,
          lastModifiedDate: formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US'),
          lastModifiedBy: this.requestAccessFormModel.requestor_DisplayName,
          attachments: [],
        });
      }
    });
     this.isAddApplicationVisible = false;
     this.isLoader = false;
  }

  selectAllApps(event: boolean) {
    this.isAppSelected = false;
    console.log('event', event);
    this.searchApplications.forEach(val => {
      val.appChecked = event;
      if(val.appChecked){
        this.isAppSelected = true;
      }
    });
  }

  selectApp() {
    this.isAppSelected = false;
    this.searchApplications.forEach(val => {
      if(val.appChecked){
        this.isAppSelected = true;
      }
    });
  }

  onBehalfOf() {
    const control = this.reqAcessForm.controls["onBehalfOf"];
    if (control.value === true) {
      this.reqAcessForm.controls["onBehalfOfUser"].enable();
    }
    else{
      this.reqAcessForm.controls["onBehalfOfUser"].disable();
      this.reqAcessForm.controls["onBehalfOfUser"].setValue('');
      this.getUserDetails();
    }
  }

  onChangeRequestFor(event: any) {
   if (event !== null && event !== undefined && event !== '') {
    console.log('event', event);
      this.isonBehalfOfdisable = true;
      this.msgraph.getGraphUsersByNameOrEmail(event).subscribe({ 
        next: (graphProp) => {
          this.requestAccessFormModel.on_Behalf_Of_AzureObjectID = graphProp.id;
          this.on_Behalf_Of_DisplayName = graphProp.surname + ", " + graphProp.givenName;
          this.requestAccessFormModel.on_Behalf_Of_Email = graphProp.mail;
          this.requestAccessFormModel.requestor_Title = graphProp.jobTitle;
          this.msgraph.getRequestorSupervisor(graphProp.id).subscribe({
            next: (data) => {
              this.requestAccessFormModel.approver_AzureObjectID = data.id;
              this.requestAccessFormModel.approver_DisplayName = data.surname + ", " + data.givenName;
              this.requestAccessFormModel.approver_Email = data.mail;
              this.reqAcessForm.controls["approver"].setValue(this.requestAccessFormModel.approver_DisplayName);
            },
            error: (err) => {
              this.requestAccessFormModel.approver_AzureObjectID = '';
              this.requestAccessFormModel.approver_DisplayName = '';
              this.requestAccessFormModel.approver_Email = '';
              this.reqAcessForm.controls["approver"].setValue('');
              console.log("Unable to graph data", err);
              this.isLoader= false
            },
            complete: () => {
              this.isLoader = false;
            }
          });
        },
        error: (err) => {
          console.log("Unable to graph data", err);
          this.isLoader= false
        },
        complete: () => {
          this.isLoader = false;
        }
    });
  }
  }

  onChangeMimic(event: any, data: any) {
    if (event !== null && event !== undefined && event !== '') {
      console.log('event', event);
      this.msgraph.getGraphUsersByNameOrEmail(event).subscribe(graphProp =>{ // Pass the value as an argument
        data.appMimic_DisplayName = graphProp.surname + ", " + graphProp.givenName;
      });
  }
}

  async searchUser(value: string, calledFrom: string) {
    this.searchUsers = [];
    if (value) {
      let users = [...await this.dataservice.searchUser(value)];
      for (let user of users) {
        this.searchUsers.push({
          defaultEmail: user?.mail,
          defaultName: user?.fullName,
          id: user?.id,
        })
      }
      if (calledFrom === 'onBehalfOf') {
        this.isonBehalfOfdisable = false;
      }
    }
  }

  async searchApplication(event: any) {
    this.searchApplications = [];
    let searchText= $('#txtAppName').val()?.toString();
    if (searchText !== null && searchText !== undefined && searchText !== '') {
      this.isLoader = true;
      this.apiService.getApplications(searchText).subscribe({
        next: (out) => {
          this.application = [...out.body];
          if(this.application.length > 0){
            this.application.forEach(val => {
              this.searchApplications.push({
                appId: Number(val.appId),
                appName: val.name,
                appShortDesc: val.shortDescription.toString().substring(0, 50),
                appChecked: false,
                appDesc: val.shortDescription,
                appProductLine: val.productLine,
                sysId: val.sysId,
              });
            });
          }
        },
      error: (err) => {
        console.log("Unable to retrieve applications", err);
        this.isLoader = false;
      },
      complete: () => {
        this.isLoader = false;
      }
    });
  }
  console.log('searchApplications', this.searchApplications);
  }

  clearApplication()
  {
    this.searchApplications = [];
    $('#txtAppName').val('');
  }

  deleteApp(id: number): void {
    this.applicationData = this.applicationData.filter(d => d.appId !== id);
    this.searchApplications = this.searchApplications.filter(d => d.appId !== id);
  }

  navigateToPageComponent(data: string){
    this.router.navigate([data]);
  }
   
}
 