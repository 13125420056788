<main class="container">
 
    <section id="column1">
      <app-page-header></app-page-header>
      <div class="loader-container" *ngIf="isLoader">
        <nz-spin nzTip="Loading..." style="position: fixed;left: 50%;top:50%;z-index: 1000;" [nzSize]="'large'">
          <!-- <nz-alert
          nzType="info"
          nzMessage="Loading Law Access Form"
          nzDescription=" "
        ></nz-alert> -->
        </nz-spin>
      </div>
      <div class="home-content">
          <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
          <div nz-col [nzSpan]="22"  [nzPush]="7">
            welcome to law access tool
          </div>
        </div>
        <div nz-row >
            &nbsp;
        </div>
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
            <div class="grid-title" nz-col [nzSpan]="12" [nzPush]="3">
                my requests
               </div>
                  <div class="grid-title" nz-col [nzSpan]="12" [nzPush]="1">
                my approvals
               </div>
            </div>    
           
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
         
            <div nz-col [nzSpan]="12" [nzPush]="3">
              <div style="background: #ECECEC; width: 390px; height: 130px; padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px;">
                   <div nz-row [nzGutter]="12">
                     <div nz-col [nzSpan]="12" [nzPush]="8">
                        <nz-card class="ant-card-head-title" nzTitle="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;pending" [nzExtra]="pendingMyReviewTemplate" nzSize="small">
                              <span *ngIf="mypendingRequests > 0" class="span-class" style="font-size: 20px;"><strong>{{mypendingRequests}}</strong></span>
                              <ng-container *ngIf="mypendingRequests == 0" >
                                No data found
                              </ng-container>
                          </nz-card>
                          <ng-template #pendingMyReviewTemplate>
                            <a (click)="ViewMyRequests('Pending')"><span nz-icon nzType="double-right" nzTheme="outline" style="color: white;"></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                          </ng-template>
                     </div>
                     </div>
                     <br/>
                 </div>
             </div>
           
          <div nz-col [nzSpan]="12" [nzPush]="1">
             <div style="background: #ECECEC; width: 390px; height: 130px; padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px;">
                <div nz-row [nzGutter]="12">
                    <div nz-col [nzSpan]="12" >
                       <nz-card class="ant-card-head-title" nzTitle="pending review" [nzExtra]="pendingapprovalReviewTemplate" nzSize="small">
                             <span *ngIf="approvalpendingRequests > 0" class="span-class" style="font-size: 20px;"><strong>{{approvalpendingRequests}}</strong></span>
                             <ng-container *ngIf="approvalpendingRequests == 0" >
                               No data found
                             </ng-container>
                         </nz-card>
                         <ng-template #pendingapprovalReviewTemplate>
                           <a (click)="ViewMyApprovalRequests('Pending Review')"><span nz-icon nzType="double-right" nzTheme="outline" style="color: white;"></span></a>
                         </ng-template>
                    
                    </div>
                    <div nz-col [nzSpan]="12">
                       <nz-card class="ant-card-head-title" nzTitle="review complete" [nzExtra]="pendingApprovalReviewCompleteTemplate" nzSize="small">
                           <span *ngIf="approvalreviewCompleteRequests > 0" class="span-class" style="font-size: 20px;"><strong>{{approvalreviewCompleteRequests}}</strong></span>
                           <ng-container *ngIf="approvalreviewCompleteRequests == 0" >
                             No data found
                           </ng-container>
                       </nz-card>
                       <ng-template #pendingApprovalReviewCompleteTemplate>
                         <a (click)="ViewMyApprovalRequests('Review Complete')"><span nz-icon nzType="double-right" nzTheme="outline" style="color: white;"></span></a>
                       </ng-template>
                    </div>
                  
                </div>
                <br/>
                <!-- <div nz-row [nzGutter]="12">
                    <div nz-col [nzSpan]="12"  [nzPush]="8">
                       <nz-card class="ant-card-head-title" nzTitle="closed" [nzExtra]="pendingApprovalClosedTemplate" nzSize="small">
                           <span *ngIf="approvalclosedRequests > 0" class="span-class" style="font-size: 20px;"><strong>{{approvalclosedRequests}}</strong></span>
                           <ng-container *ngIf="approvalclosedRequests == 0" >
                             No data found
                           </ng-container>
                       </nz-card>
                       <ng-template #pendingApprovalClosedTemplate>
                         <a (click)="ViewMyApprovalRequests('Closed')"><span nz-icon nzType="double-right" nzTheme="outline" style="color: white;"></span></a>
                       </ng-template>
                    </div>
                  </div> -->
              </div>
          </div>
        </div>
        <div nz-col [nzSpan]="24"  [nzPush]="1">
          &nbsp;
        </div>
      </div>
    </section>
  </main>
  