import { Config } from "../../node_modules/@cvx/cal"

const calConfig: Config = {
  autoSignIn: true,
  popupForLogin: false,
  cacheLocation: "localStorage",
  instance: "https://login.microsoftonline.com/",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  clientId: "aae09ce5-e827-4aa3-89bf-ce9b12087deb",
  //redirectUri: "https://localhost:44300",    
  redirectUri: "https://lawaccess-dev.azure.chevron.com",    
  oidcScopes: ["openid", "profile", "email","User.Read","offline_access"],
  graphScopes: ["GroupMember.Read.All", "Group.Read.All", "User.Read", "User.Read.All"]
}
 
export const environment = {
  calConfig: calConfig,
  production: false,
  sendEmail: true,
  //webUrl: "https://localhost:44300",
  groupName: 'Law%2520-%2520Function%22',
  webUrl: "https://lawaccess-dev.azure.chevron.com",
  msGraphUrl: "https://graph.microsoft.com/v1.0/",
  //apiEndpoint: "https://localhost:7035/api",
  apiEndpoint: "https://lawaccdev-api.azurewebsites.net/api",
};
