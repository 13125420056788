import { Component, ViewChild, EventEmitter, Input, Optional, Output, ChangeDetectorRef} from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent, NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { SelectionModel } from '@angular/cdk/collections';
import { NzFormatEmitEvent, NzTreeModule } from 'ng-zorro-antd/tree';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { PAGE_INDEX, PAGE_SIZE,NO_RESULT, Menu } from 'src/app/constants/constants';
import { routes } from 'src/app/app-routing.module';
import { CalAngularService } from '@cvx/cal-angular';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DateFormat } from 'src/app/constants/constants';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { formatDate } from '@angular/common';


interface SearchRequest {
  approverId: string;
  requestorId: string;
  pageNo: number;
  pageSize: number;
  requestNo: string;
  requestor: string;
  onBehalfOf: string;
  status: string;
  effectiveDate: string;
  requestedDate: string;
}

@Component({
  selector: 'app-myapprovals',
  templateUrl: './myapprovals.component.html',
  styleUrls: ['./myapprovals.component.less'],
})



export class MyapprovalsComponent { 
  
  isLoader:boolean = false;
  accessRequests: any[] = [];
  listOfAccessRequests: any[] = [];
  format:any=DateFormat.mmddyyyy
  searchValue: string = '';
  visible: boolean = false;
  searchForm = this.fb.group({
    requestNo: [],
    requestor: [],
    onbehalfOf: [],
    status: [],
    effectiveDate: [],
    requestedDate: [],
  })
  statusParam ='';
  pageSize:number=PAGE_SIZE;
  pageIndex:number=PAGE_INDEX;
  searchRequest: SearchRequest = {
    approverId:'',
    requestorId: '',
    pageNo: this.pageIndex,
    pageSize: this.pageSize,
    requestNo: '',
    requestor: '',
    onBehalfOf: '',
    status: '',
    effectiveDate: '',
    requestedDate: ''
  };
  totalCount: number = 0;

  defaultCheckedKeys = [''];
  defaultExpandedKeys = ['Status'];

  readonly nodes = [
    {
      title: 'Status',
      key: 'Status',
      children: [
        { title: 'Pending Review', key: 'Pending Review', isLeaf: true },
        { title: 'Review Complete', key: 'Review Complete', isLeaf: true },
        { title: 'Closed', key: 'Closed', isLeaf: true }
      ]
    }
  ];
  private groups = 'Law - Managed Services Team Members1';
  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private apiService: LFDAPIService, private msgraph: MsGraphService , private authService: CalAngularService,private router: Router, private session: SessionStorageService, private dataservice: DataService, private cdr: ChangeDetectorRef) {
  this.statusParam = this.session.getItem('status')
  }
  
 

async ngOnInit() {
  let isTokenAvailable = await this.dataservice.getAuthorizationToken();
    if(isTokenAvailable){
// if (await this.canAccess()) {
//   console.log('You are in one of the following groups: ' + this.groups + '.');
// }
    this.isLoader = true;
      this.authService.getUserPropertiesFromMsGraph(["id,displayName","cai", "jobTitle","photo","surname","givenName","officeLocation"]).subscribe(graphProp =>{
        if (this.statusParam != undefined && this.statusParam != null && this.statusParam != '') {
          this.searchForm.patchValue({status: this.statusParam});
          this.defaultCheckedKeys = [this.statusParam];
          this.searchRequest.status = this.statusParam;
          this.session.setItem("status",'');
        }
        this.searchRequest.approverId = graphProp.id;
       this.getAllMyApprovals(this.searchRequest);
      });
    }
}

canAccess(): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
      this.authService.isInGroup(this.groups).subscribe(async (inGroup: boolean) => {
          if (inGroup) {
              console.log('You are in one of the following groups: ' + this.groups + '.');
              resolve(true);
          } else {
              console.log('You are not in one of the following groups: ' + this.groups + '.');
              this.router.navigateByUrl('/unauthorized');
              resolve(false);
          }
      });
  });
}

getPageIndexNumber(pageIndex:number){
  this.pageIndex=pageIndex;
 let form: UntypedFormGroup=  this.searchForm;
 this.searchRequest.pageNo=pageIndex;
 this.searchRequest.requestNo=form.value.requestNo;
 this.searchRequest.requestor=form.value.requestor;
 this.searchRequest.onBehalfOf=form.value.onbehalfOf;
 this.searchRequest.status=this.statusParam;
//  this.searchRequest.effectiveDate=formatDate(form.value.effectiveDate,'mm/dd/yyyy','en-US');
//  this.searchRequest.requestedDate=formatDate(form.value.requestedDate,'mm/dd/yyyy','en-US');

 this.getAllMyApprovals(this.searchRequest);
}
getPageSizeNumber(pageIndex:number){
 this.pageIndex=pageIndex;
 let form: UntypedFormGroup=  this.searchForm;
 this.searchRequest.pageNo=pageIndex;
 this.searchRequest.requestNo=form.value.requestNo;
 this.searchRequest.requestor=form.value.requestor;
 this.searchRequest.onBehalfOf=form.value.onbehalfOf;
 this.searchRequest.status=this.statusParam;
//  this.searchRequest.effectiveDate= formatDate(form.value.effectiveDate,'mm/dd/yyyy','en-US');
//  this.searchRequest.requestedDate=formatDate(form.value.requestedDate,'mm/dd/yyyy','en-US');

 this.getAllMyApprovals(this.searchRequest);
}

onChangeEffectiveDate(result: Date): void {
  console.log('onChange: ', result);
  if (result != null)
  {
    this.searchRequest.effectiveDate=formatDate(result,'MM/dd/yyyy','en-US');
  }
  else
  {
    this.searchRequest.effectiveDate='';
  }
}

onChangeRequestedDate(result: Date): void {
  console.log('onChange: ', result);
  if (result != null)
  {
    this.searchRequest.requestedDate=formatDate(result,'MM/dd/yyyy','en-US');
  }
  else
  {
    this.searchRequest.requestedDate='';
  }
}

nzEvent(event: NzFormatEmitEvent): void {
  console.log(event);
  this.statusParam = '';
  if(event.keys != undefined){
    event.keys.forEach((key) => {
      
        if(key == 'Status')
        {
          this.statusParam = this.statusParam.concat('Pending Review,Review Complete,Closed')
        }
        else
        {
          this.statusParam = this.statusParam.concat(",")
          this.statusParam = this.statusParam.concat(key)
        }
    });
    console.log('statusParam', this.statusParam);
  }
}

getAllMyApprovals(searchRequest: SearchRequest) {
  console.log('searchRequest', searchRequest);
  //searchRequest.approverId = 'e4be06d4-19e8-4c05-949e-d97888c10496';
  this.apiService.getMyApprovals(searchRequest).subscribe({
    next: (data) => {
      this.accessRequests = [...data.body];
      console.log('accessRequests', this.accessRequests);
      this.totalCount = this.accessRequests.length > 0? this.accessRequests[0].totalCount:0;
  },
  error: (err) => {
    console.log("Unable to retrieve Case Project Publication TabData", err);
    this.isLoader= false
  },
  complete: () => {
    this.isLoader = false;
  }
});
}

submitForm() {
  let form: UntypedFormGroup=  this.searchForm;
 this.searchRequest.pageNo=this.pageIndex;
 this.searchRequest.requestNo=form.value.requestNo;
 this.searchRequest.requestor=form.value.requestor;
 this.searchRequest.onBehalfOf=form.value.onbehalfOf;
 this.searchRequest.status=this.statusParam;
//  this.searchRequest.effectiveDate=formatDate(form.value.effectiveDate,'MM/dd/yyyy','en-US');
//  this.searchRequest.requestedDate=formatDate(form.value.requestedDate,'mm/dd/yyyy','en-US');
  this.getAllMyApprovals(this.searchRequest);
}

ViewRequestDetails(requestAccessId:number){
   //this.session.setItem("requestAccessId",requestAccessId);
   this.session.setItem("calledFrom","my_requests");
   this.router.navigate( 
     ['/requestapproval/'+requestAccessId],
     { }
   );
  //queryParams: { requestaccessId: requestAccessId , calledFrom: 'my_approvals'}
}

reset(): void {
  this.searchValue = '';
  this.search();
}

search(): void {
  this.visible = false;
  this.accessRequests  = this.accessRequests.filter((item) => item.name.indexOf(this.searchValue) !== -1);
}
}
